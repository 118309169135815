@import '../../../colors.scss';

.footerWrapper {
    background-color: $primary;
    padding-top: 8px;

    .footerLinksContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .footerLink {
            color: $white;
            margin: 0px 16px;
            cursor: pointer;
        }
    }

    .footerCopyRight {
        color: $white;
        opacity: 0.8;
        text-align: center;
    }

    .StartYourJourneyButton {
        background: linear-gradient(to bottom right, $third, $light-blue);
        border-radius: 8px !important;
        color: $primary !important;
        padding: 8px 32px !important;

        a {
            text-decoration: none !important;
            text-transform: capitalize;
            color: $primary !important;
            font-weight: bold !important;
        }

        &:hover {
            background: linear-gradient(to bottom right, $third, $primary) !important;
        }
    }
}