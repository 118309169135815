@import '../../../colors.scss';

.SystemSecondComponente-card {
    margin: 24px auto;
    background: transparent;
    box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
    position: relative;
    width: 240px;
    height: 330px;
    border-radius: 16px;
    overflow: hidden;
}

.SystemSecondComponentwave {
    position: absolute;
    width: 540px;
    height: 700px;
    opacity: 0.6;
    left: 0;
    top: 0;
    margin-left: -50%;
    margin-top: -70%;
    background: linear-gradient(744deg, #000, #303030 60%, #00C9C8);
}

.SystemSecondComponenticon {
    margin-bottom: 8px;
}

.SystemSecondComponentinfotop {
    text-align: center;
    font-size: 20px;
    position: absolute;
    top: 5.6em;
    left: 0;
    right: 0;
    color: rgb(255, 255, 255);
    font-weight: 600;
}

.SystemSecondComponentname {
    font-size: 14px;
    position: relative;
    top: 0.5em;
    padding: 8px;
}

.SystemSecondComponentwave:nth-child(2),
.SystemSecondComponentwave:nth-child(3) {
    top: 210px;
}

.SystemSecondComponentplaying .SystemSecondComponentwave {
    border-radius: 40%;
    animation: wave 3000ms infinite linear;
}

.SystemSecondComponentwave {
    border-radius: 40%;
    animation: wave 55s infinite linear;
}

.SystemSecondComponentplaying .SystemSecondComponentwave:nth-child(2) {
    animation-duration: 4000ms;
}

.SystemSecondComponentwave:nth-child(2) {
    animation-duration: 50s;
}

.SystemSecondComponentplaying .SystemSecondComponentwave:nth-child(3) {
    animation-duration: 5000ms;
}

.SystemSecondComponentwave:nth-child(3) {
    animation-duration: 45s;
}

@keyframes wave {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}