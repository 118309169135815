@import '../../../colors.scss';

.AboutUsThirdComponentWrapper {
    margin: 32px 0px;
    width: 100%;
    padding: 16px 0px;
    background: linear-gradient(to bottom right, $primary, $third);
    color: $white;

    .AboutUsThirdComponentTab {
        font-size: 20px !important;
        text-transform: capitalize !important;
        color: $white;
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
        font-weight: bold;
        color: $white;
    }

    .AboutUsThirdComponentImageContainer {
        width: 100%;
        height: 50vh;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}