@import '../../../colors.scss';

.HomePageThirdComponentWrapper {
    padding-bottom: 48px;

    .HomePageThirdComponentImagesContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        gap: 24px;

        .HomePageThirdComponentImages {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .HomePageThirdComponentImagesText {
                font-weight: bold;
                margin-top: 8px;
            }
        }

        .HomePageThirdComponentPlusImage {
            margin: 0px 48px;
        }
    }
}