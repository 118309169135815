@import '../../colors.scss';

.contactUsWrapper {
    background-color: rgba(255, 255, 255, 0.13);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    backdrop-filter: blur(10px);
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 16px;
    border-radius: 16px;

    .contactUsInputLabel {
        color: $white !important;
        font-weight: bold !important;
    }

    .contactUsInput {
        color: $white !important;
        border: 1px solid $white !important;
        outline: none !important;
    }


    .contactUsRandomNumber {
        color: $white;
        border: 1px solid $white;
        width: auto;
        display: inline-block;
        border-radius: 8px;
        padding: 4px 16px;
    }

    .phoneNumberInput {
        border: 1px solid $white !important;
        padding: 6px 8px;
        border-radius: 0.25rem;

        .PhoneInputInput {
            border: none !important;
            outline: none !important;
            background-color: transparent;
            color: $white !important;
        }
    }


    .react-datepicker-wrapper {
        width: 100%;

        .dateLossInput {
            width: 100% !important;
            border: 1px solid $white !important;
            padding: 8px;
            border-radius: 0.25rem;
            background-color: transparent !important;
            color: $white !important;
        }
    }

    .submitButton {
        background-color: $third !important;
        color: $white !important;
        padding: 8px 0px;
        border-radius: 8px !important;
    }
}

.css-4jnixx-MuiStack-root {
    padding: 0 !important;
}