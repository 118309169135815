@import '../../../colors.scss';

.SldieShowWrapper {

    height: 80vh;

    .SlideShowItemContainer {
        width: 100%;
        height: 100%;
        position: relative;

        .SlideShowItemImage {
            width: 100%;
            height: 100%;
        }

        .SlideShowtemOverlay {
            position: absolute;
            top: 25%;
            left: 10%;
            // line-height: 70px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .SlideShowtemOverlayTitle {
                text-transform: capitalize;
                color: $white;
                font-weight: bold;
            }

            .SlideShowtemOverlaySubTitle {
                text-transform: capitalize;
                color: rgba($white , 0.8);
                margin: 32px 0px;
            }

            .SlideShowtemOverlayButton1 {
                background-color: transparent !important;
                color: $white !important;
                border-radius: 0px !important;
                // border: 1px solid var(--white) !important;
                padding: 8px 16px !important;
            }

            .SlideShowtemOverlayButton2 {
                background-color: transparent !important;
                border: 1px solid $white !important;
                color: $white !important;
                border-radius: 0px !important;
                padding: 8px 16px !important;
            }

            .SlideShowtemOverlayButton3 {
                background-color: rgba($primary , 0.8) !important;
                color: $white !important;
                border-radius: 100px !important;
                padding: 8px 16px !important;
            }
        }
    }
}

.SlideShowtemOverlayButton1:hover {
    transform: scale(1.05) !important;
    transition: transform 0.5s ease !important;
}

.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    background: $white !important;
}