@import '../../../colors.scss';

.AboutUsSecondComponentTitlesContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .AboutUsSecondComponentTitle {
        font-weight: bold;
        color: $black;
    }

    .AboutUsSecondComponentSubTitle {
        margin-top: 16px;
        text-align: left;
        color: rgba($black, 0.8);
    }
}

.AboutUsSecondComponentCardsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    margin: 32px 0px;
}

.card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 300px;
    border-radius: 24px;
    line-height: 1.6;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
    margin: 16px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    height: 100%;
    padding: 16px;
    border-radius: 22px;
    color: #ffffff;
    overflow: hidden;
    background: $primary !important;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content::before {
    position: absolute;
    content: "";
    top: -4%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%);
    background: $secondary;
    z-index: -1;
    transform-origin: bottom;

    border-radius: inherit;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content::after {
    position: absolute;
    content: "";
    top: -8%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%);
    background: $fourth;
    z-index: -2;
    transform-origin: bottom;
    border-radius: inherit;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.card:hover {
    transform: translate(0px, -16px);
}

.card:hover .content::before {
    rotate: -8deg;
    top: 0;
    width: 100%;
    height: 100%;
}

.card:hover .content::after {
    rotate: 8deg;
    top: 0;
    width: 100%;
    height: 100%;
}