@import '../../colors.scss';



.signinWrapper {
    background-color: $primary !important;
    height: 100vh;

    .signinbackground {
        width: 430px;
        height: 520px;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;

        .signinshape {
            height: 200px;
            width: 200px;
            position: absolute;
            border-radius: 50%;
        }
    }


    .signinshape:first-child {
        background: linear-gradient($third,
                $light-blue);
        left: -80px;
        top: -80px;
    }

    .signinshape:last-child {
        background: linear-gradient(#00ADAD,
                #003235);
        right: -30px;
        bottom: -80px;
    }

    form {
        height: 520px;
        width: 400px;
        background-color: rgba(255, 255, 255, 0.13);
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        border-radius: 10px;
        backdrop-filter: blur(10px);
        border: 2px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
        padding: 50px 35px;
    }

    form * {
        color: #ffffff;
        letter-spacing: 0.5px;
        outline: none;
        border: none;
    }

    form h3 {
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
        text-align: center;
    }

    label {
        display: block;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
    }

    input {
        display: block;
        height: 50px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.07);
        border-radius: 3px;
        padding: 0 10px;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 300;
    }

    ::placeholder {
        color: #e5e5e5;
    }

    button {
        margin-top: 32px;
        width: 100%;
        background-color: #ffffff;
        color: #080710;
        padding: 8px 0;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        cursor: pointer;
    }

    .signinback {
        margin-top: 30px;
        width: 40px;
        border-radius: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.27);
        color: #eaf0fb;
        text-align: center;
    }

    .signinback:hover {
        background-color: rgba(255, 255, 255, 0.47);
    }

}