@import '../../../colors.scss';

.ScenariosComponentUpdated {
    margin: 32px 0;

    .scenario-card {
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

        &:hover,
        &.active {
            transform: translateY(-10px);
            box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.4);
        }

        &.active {
            border: 2px solid $primary;
        }
    }

    .selected-scenario-details {
        margin-top: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;

        .selected-scenario-image {
            margin-bottom: 32px;
            padding: 16px;
            border-radius: 16px;
            width: 50%;
            max-width: 200px;
            height: auto;
            background-color: $primary;
        }
    }
}