@import '../../colors.scss';

.ticketsWrapper{
    width: 50%;
    background-color: rgba(255, 255, 255, 0.13);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    backdrop-filter: blur(10px);
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 16px;
    border-radius: 16px;
    .ticketProgressTable {
        width: 100%;
        margin: 0;
        position: relative;
        border-collapse: collapse;
        color: $white;

        .tableHeader {
            padding: 16px;
            position: sticky;
            top: -16px;
            background-color: $primary;
            color: $white;
            text-align: center;
        }

        .tableHeader:nth-child(1) {
            border-radius: 10px 0px 0px 10px;
        }

        .tableHeader:nth-child(3) {
            border-radius: 0px 10px 10px 0px;
        }
    }
}